import Div100vh from 'react-div-100vh'

//import connectGif from './ConnectingAnimation.gif'
import './App.css';
import {useState, useEffect} from "react"
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios'

import WOF_logo_w from "./WOF_logo-w.png" 
import WOF_logo_g from "./WOF_logo.png"
import fotter_bar_black from "./Grid_black.png"
import fotter_bar_white from "./Grid_white.png"
import PrivacyImage from "./PrivacyPolicy.png"
import AcceptButtonImage from "./btn.png"
import ConnectingGif from "./Connecting_230207.gif"
import SelectAnnImage from "./selectmode.png"
import PhotoButtonImage from "./photo-button.png"
import MovieButtonImage from "./movie-button.png"
import TapMessageImg from "./tap.png"
import TakePhotoImage from "./photobutton-ui.png"
import MovieRecButtonImage from "./movie-rec-button.png"

import CountDownAnnImage from "./countdown-ann.png"
import CountDownMovieAnnImage from "./countdown-movie-ann.png"

import OkBUttonImage from "./ok-button.png"
import ReshotButtonImage from "./retake.png"

import LoadingGif from "./Loading_230207.gif"

// import NoSleep from 'nosleep/NoSleep'
import NoSleep from "nosleep.js";
import useWindowDimensions from './useWindowDimensions'



// https://scriptverse.academy/tutorials/reactjs-chat-websocket.html
//https://scriptverse-academy.translate.goog/tutorials/reactjs-chat-websocket.html?_x_tr_sl=auto&_x_tr_tl=ja&_x_tr_hl=ja&_x_tr_pto=wapp
//https://mhnpd.github.io/react-loader-spinner/oval

//WebSocket URL
const URL = 'wss://r2pkk4kkb7.execute-api.ap-northeast-1.amazonaws.com/production'
//const URL = 'wss://wgcw1d0rnj.execute-api.ap-northeast-1.amazonaws.com/dev'

//Result URL
const resultURL = 'https://result.content-studio-wof-tokyoshibuya.com/'

//AgreementService https://agreementservice.svs.nike.com/rest/agreement/privacyPolicy/ja/JP/test.uxid.donotuse/text/true
const AgreementServiceAPI_URL = 'rest/agreement/privacyPolicy/ja/JP/your.current.uxid.test/text/false'

//Frame総数
const frameNum = 3

const FotterMessage=()=>{
  return(
    <div className='pt-5 text-xs link text-black mr-auto pl-8'>
      <p> </p>
      <p><a href='https://agreementservice.svs.nike.com/jp/ja_jp/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=JP&language=ja&requestType=redirect' target="_blank" rel="noopener noreferrer">プライバシーポリシー</a> / <a href='https://agreementservice.svs.nike.com/gb/en_gb/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=GB&language=en&requestType=redirect' target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
    </div>
  )
}

const FotterMessage_w=()=>{
  return(
    <div className='pt-5 text-xs link_w text-white mr-auto pl-8'>
      <p> </p>
      <p><a href='https://agreementservice.svs.nike.com/jp/ja_jp/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=JP&language=ja&requestType=redirect' target="_blank" rel="noopener noreferrer">プライバシーポリシー</a> / <a href='https://agreementservice.svs.nike.com/gb/en_gb/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=GB&language=en&requestType=redirect' target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
    </div>
  )
}

const ParamDebug = (props) =>{
  return(
    <div>
      ## WebSocket Debug Param
      <div>SessionID:{props.sessionID}</div>
      <div>ClientID:{props.clinetID}</div>
      <div>FrameID:{props.frameID}</div>
      <div>SceneID:{props.sceneID}</div>
      <div>{props.message}</div>
      <p>{props.recieveMessage}</p>
    </div>
  )
}

const AgreementText = () =>{
  const [text, setText] = useState('')
  const [bloaded, setLoadFlag] = useState(0)

  useEffect(()=>{
    axios.get(AgreementServiceAPI_URL)
    .then(res =>{
      setText(res.data)
      setLoadFlag(1)
    })
  },[])

  if(bloaded === 1){
    return (
      <div>
        {text}
      </div>
    )
  }else{
    return (
      <div>
        Loading...
      </div>
    )
  }
}

const RedirectResultPage = (props) => {
  window.location.href = props.URL;
}

const SnapSync = () =>{
  const search = useLocation().search
  const query = new URLSearchParams(search)

  const [clinetID, setClientID] = useState(query.get('ClientID'))
  const [sessionID, setSessionID] = useState(query.get('SessionID'))
  const [deviceID, setDeviceID] = useState('')
  const [frameID, setFrame] = useState(0)
  const [sceneID, setScene] = useState(1)//default 0
  const [ended, setEnded] = useState(0) 
  const [message, setMessage] = useState([])
  const [operationID, setOperationID] = useState(0)
  const [recieveMessage, setRecieveMessage] = useState([])
  const [redirectURL, setRedirectURL] = useState([])
  const [isPhotoMode, setPhotoMode] = useState(1)
  const [ws, setWs] = useState(new WebSocket(URL))

  const windowDimensions = useWindowDimensions();


  const debugButtonAction = () =>{
    const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,1)
    console.log("Debug:%s",wsjson)
//    ws.send(JSON.stringify(wsjson));
    ws.send(wsjson);

    setMessage(wsjson)

  }

  const debugButtonDisconnectAction = ()=>{
    ws.close()
  }

  //承認ボタン処理
  const acceptPersonalData = () =>{
    setScene(2)//接続
    //WebSocket送信(Operation:3)
    const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,2)
    ws.send(wsjson)
    setMessage(wsjson)

    console.log("acceptPersonalData_接続ボタンが押された_"+wsjson)

    //3秒後撮影のモードへ
    setTimeout(function(){
      setScene(3)
    }.bind(this),9000)
  }


  //Frameボタン処理
  const SelectFrame = () =>{
    //ToDo
    console.log("Frameボタンが押された")
    setFrame((frameID + 1)%frameNum)
    const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,2)
    ws.send(wsjson)
    setMessage(wsjson)

  }

  //Photo Select ボタン処理
  const PhotoButton = (props) =>{
    //ToDo
    setScene(2)//接続

    console.log("Photoモード選択")
    setPhotoMode(1)
    const reURL = resultURL+"?ClientID="+clinetID+"&SessionID="+sessionID+"&Photo=1";
    setRedirectURL(reURL);

    const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,5)
    ws.send(wsjson)

    setMessage(wsjson)

    //3秒後撮影のモードへ
    setTimeout(function(){
      setScene(5)
    }.bind(this),1000)
  }

 //Movie Select ボタン処理

 const MovieButton = () =>{
  //ToDo
  setScene(2)//接続

  console.log("Movieモード選択")
  setPhotoMode(0);
  const reURL = resultURL+"?ClientID="+clinetID+"&SessionID="+sessionID+"&Photo=0";
  setRedirectURL(reURL);

  const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,6)
  ws.send(wsjson)

  setMessage(wsjson)

  //1秒後撮影のモードへ
  setTimeout(function(){
    setScene(6)
  }.bind(this),1000)
}


//OKボタン
const OkButton = () =>{
  //ToDo
  setScene(9)//Uploading
  
  // const reURL = resultURL+"?ClientID="+clinetID+"&SessionID="+sessionID+"&Photo="+isPhotoMode.toString();
  // setRedirectURL(reURL);

  console.log("OK選択 - Opcode:4待ち")
  const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,9)
  ws.send(wsjson)

  setMessage(wsjson)
}

//Reshotボタン
const ReshotButton = () =>{
  //ToDo
  setScene(2)//一回Connectingを挟む

  console.log("Reshot選択")
  const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,8)//再撮影のOperationコード
  ws.send(wsjson)

  setMessage(wsjson)

  //1秒後モード選択へ
  setTimeout(function(){
    setScene(3)
  }.bind(this),1000)
}


  //撮影ボタン処理
  const ShootSnapSync = () =>{
    console.log("撮影ボタンが押された")
    setScene(7)//接続
    
    const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,3)
    ws.send(wsjson)
    setMessage(wsjson)

  }

  //WebSocket周りの処理
  useEffect(()=>{
    //NoSleep
    const noSleep = new NoSleep();
    noSleep.enable();
    
    ws.onopen=()=>{
      console.log('WebSocket Connected')
      setClientID(query.get('ClientID'))
      setSessionID(query.get('SessionID'))
      
      setScene(1)
      const wsjson = createWSJson(clinetID,sessionID,deviceID,frameID,1)
      ws.send(wsjson);

    }

    //---------------------------------
    ws.onmessage = (e) =>{
      console.log("on Recieve!")
      const message = JSON.parse(e.data);
      console.log("recieve:%s",e.data)
      setRecieveMessage(e.data)

      setOperationID(message.Operation);

      if(message.Operation === 4){
    
        setEnded(1)
      }else if(message.Operation === 7){
        console.log("reshot check")
        setScene(8);
      }else if(message.Operation === -1){
        console.log("connecting error")
        setScene(-1)
        ws.close()

      }

      // console.log(e)
    }

    return () => {
      ws.onclose = () => {
        console.log('Disconnected');
        //setWs(new WebSocket(URL));
        
        // setTimeout(function(){
        //   setWs(new WebSocket(URL));
        // }.bind(this),5000)
      }
    }
  },[ws.onmessage, ws.onopen, ws.onclose])

  //-----------------------------------


  //------------------------------------
  if(ended === 1){
    return(
      <div className='BackGround_w'>
      <div className='Header'>
        <div className='logo'>
          <img src={WOF_logo_g} />
        </div> 
      </div>
      
      <div className='Contents'>
        <div className='mx-auto pt-5'>          
          ページを自動でジャンプしない場合は<a href={redirectURL} className='text-sky-600'>こちら</a> 
          <RedirectResultPage URL={redirectURL}/>
        </div>
      </div>
      
      <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
    </div>
  )
  }
//  -----------------------------------
  //横向きの場合

  if(windowDimensions.width > windowDimensions.height){
    return(
      <div className='App'>
        <div className='BackGround_g'>

          <div className='Contents text-white'>
            <p>端末を縦にしてお楽しみください。</p>
            <p>Hold Your smartphone vertically.</p>

          </div>

        </div>
      </div>
    );
  }
  
  
  //----------------------------------------
  
  if(sceneID === 1){ //1 承認画面
    return(
      <div className='BackGround_w' >
  
        <div className='Header'>
            <div className='logo'>
              <img src={WOF_logo_g} /> 
            </div>
        </div>
      
        <div className='Contents'>
            <div className='attention pb-5'>
              <img src={PrivacyImage}/>
            </div>
            
            <div className='pt-10'>
            <div className = "ContentsBox_JP link">
              <p>続行することで、Nikeの<a href='https://agreementservice.svs.nike.com/jp/ja_jp/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=JP&language=ja&requestType=redirect' target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に同意したことになります</p>
            </div>
            <div className = "ContentsBox_En link pt-2">
              <p>By Clicking Next, You agree to Nike's <a href='https://agreementservice.svs.nike.com/gb/en_gb/rest/agreement?agreementType=privacyPolicy&uxId=com.nike.unite&mobileStatus=true&country=GB&language=en&requestType=redirect' target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
            </div>
            </div>

            <div className='acceptbutton pt-8'>
              <button className = "" value="Accept" onClick={acceptPersonalData}>
                <img src={AcceptButtonImage}/>
              </button>
            </div>
        </div>

        <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
  
      </div>
      )


  }else if(sceneID === 2){ //2 Connectingシーン 
    return(
 
    <div className='BackGround_g' >
      <div className='Header'>
          <div className='logo'>
            <img src={WOF_logo_w} /> 
          </div> 
      </div>

      <div className='Contents'>
        <div className='GIF_Contents'>
          <img src={ConnectingGif}/>
        </div>
      </div>
    
      <div className='Fotter'>
        <div className='FotterImage'>
          <img src={fotter_bar_white}/>
        </div>
        <FotterMessage_w/>
      </div>
    </div>
    )

  }else if(sceneID === 3){ //3 Select Mode
    return(
      <div className='BackGround_w'>
  
        <div className='Header'>
          <div className='logo'>
              <img src={WOF_logo_g} /> 
            </div> 
        </div>
     
        <div className='Contents'>
              <div className='mx-auto pt-10'>
                <img src={SelectAnnImage}/>
              </div>
              <div className='mx-auto pt-5'>
                <button className = "selectionButton" value="Accept" onClick={PhotoButton}>
                  <img src={PhotoButtonImage}/>
                </button>
              </div>

              <div className='mx-auto pt-3'>
                <button className = "selectionButton" value="Accept" onClick={MovieButton}>
                  <img src={MovieButtonImage}/>
                </button>
              </div>
        </div>
        
        <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
      </div>
    )
  }else if(sceneID === 4){//4 選択
    //Scene2と同じなので割愛
  }else if(sceneID === 5){ //5 スチール撮影モード

    return(
      <div className='BackGround_w'>
      
        <div className='Header'>
            <div className='logo'>
              <img src={WOF_logo_g} /> 
            </div> 
        </div>
   
        <div className='Contents'>
          <div className='mx-auto'>
            <img src={TapMessageImg}/>
          </div>
          <div className='mx-auto pt-5'>
            <button className = "selectionButton" value="Accept" onClick={ShootSnapSync}>
              <img src={TakePhotoImage}/>
            </button>
          </div>
          <div className='mx-auto pt-5'>
            <img src={CountDownAnnImage}/>
          </div>
        </div>
      
        <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
      </div>
    )

  }else if(sceneID === 6){ //6 動画撮影モード

    return(
      <div className='BackGround_w'>
        <div className='Header'>
            <div className='logo'>
              <img src={WOF_logo_g} /> 
            </div>
        </div>
   
        <div className='Contents'>
          <div className='mx-auto pt-5'>
            <button className = "selectionButton" value="Accept" onClick={ShootSnapSync}>
              <img src={MovieRecButtonImage}/>
            </button>
          </div>
          <div className='mx-auto pt-5'>
            <img src={CountDownMovieAnnImage}/>
          </div>
        </div>
      
        <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
    </div>
    )
  }else if(sceneID === 7){ // 7 Waiting（処理街画面）
    return(
      <div className='BackGround_g' >
      <div className='Header'>
        <div className='logo'>
          <img src={WOF_logo_w} /> 
        </div> 
      </div>

      <div className='Contents'>
        <div className='GIF_Contents'>
          <img src={LoadingGif}/>
        </div>
      </div>
    
      <div className='Fotter'>
        <div className='FotterImage'>
          <img src={fotter_bar_white}/>
        </div>
        <FotterMessage_w/>
      </div>
    </div>
    )
    
  }else if(sceneID === 8){ //8 再撮影確認モード
    return(
      <div className='BackGround_w'>
        <div className='Header'>
          <div className='logo'>
            <img src={WOF_logo_g} /> 
          </div>
        </div>
     
        <div className='Contents'>
          <div className='mx-auto pt-5'>
            <button className = "selectionButton" value="Accept" onClick={OkButton}>
              <img src={OkBUttonImage}/>
            </button>
          </div>

          <div className='mx-auto pt-5'>
            <button className = "selectionButton" value="Accept" onClick={ReshotButton}>
              <img src={ReshotButtonImage}/>
            </button>
          </div>
        </div>
        
        <div className='Fotter'>
          <div className='FotterImage'>
            <img src={fotter_bar_black}/>
          </div>
          <FotterMessage/>
        </div>
      </div>
    )


  }else if(sceneID === 9){ // 9 UpLoading
    return(
      <div className='BackGround_g' >
      <div className='Header'>
        <div className='logo'>
          <img src={WOF_logo_w} /> 
        </div> 
      </div>

      <div className='Contents'>
        <div className='GIF_Contents'>
          <img src={LoadingGif}/>
        </div>
      </div>
    
      <div className='Fotter'>
        <div className='FotterImage'>
          <img src={fotter_bar_white}/>
        </div>
        <FotterMessage_w/>
      </div>
    </div>
    )

  }else if(sceneID === 10){ // 10 Redirect
    return(
      <div className='BackGround_w'>
      <div className='Header'>
        <div className='logo'>
          <img src={WOF_logo_g} /> 
        </div> 
      </div>
   
      <div className='Contents'>
        <div className='mx-auto pt-5'>          
          ページを自動でジャンプしない場合は<a href={redirectURL} className='text-sky-600'>こちら</a> 
          <RedirectResultPage URL={redirectURL}/>
        </div>
      </div>
      
      <div className='Fotter'>
        <div className='FotterImage'>
          <img src={fotter_bar_black}/>
        </div>
        <FotterMessage/>
      </div>
    </div>
  )
  }else if(sceneID === -1){
    return(
    <div className='BackGround_w'>
      <div className='Header'>
        <div className='logo'>
          <img src={WOF_logo_g} /> 
        </div> 
      </div>
 
    <div className='Contents'>
          <div className='mx-auto'>          
            <div className='bg-white p-5 font-bold'>
              <p>接続が切断されました。</p>
              <p>QRコードを再度読み込んでください。</p>
            </div>
        </div>
    </div>
    
    <div className='Fotter'>
      <div className='FotterImage'>
          <img src={fotter_bar_black}/>
        </div>
        <FotterMessage/>
      </div>
  </div>)
  }

  //Default エラー処理
  return(
    <div className='BackGround_w'>
      <div className='Header'>
          <img src={WOF_logo_g} /> 
      </div>
 
    <div className='Contents'>
          <div className='mx-auto'>          
            <div className='bg-white p-5 font-bold'>
            <p className='pt-5 font-bold text-center'>Waiting... {operationID}</p>

            </div>
        </div>
    </div>
    
    <div className='Fotter'>
        <div className='FotterImage'>
          <img src={fotter_bar_black}/>
        </div>
        <FotterMessage/>
      </div>
  </div>)

} 




function createWSJson(clientid,sessionid,deviceid,frameid,op){
  const message ={
    action:"sendMessage",
    ClientID:clientid,
    SessionID:sessionid,
    DeviceID:"",
    Frame:frameid,
    Operation:op
  }
  return JSON.stringify(message)

}

function App() {
  const windowDimensions = useWindowDimensions();

  // if(windowDimensions.width > windowDimensions.height){
  //   console.log("横向きになった")
  //   return(
  //     <div className='App'>
  //       <div className='BackGround_g'>

  //         <div className='Contents text-white'>
  //           <p>端末を縦にしてお楽しみください。</p>
  //           <p>Hold Your smartphone vertically.</p>

  //         </div>

  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className='App'>
      <Router>  
        <SnapSync/>
      </Router>
    </div>
  );

}

export default App;
